import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';  // Assuming you have a Home component
import AboutUs from './AboutUs';  // Assuming you have an AboutUs component

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
    );
}

export default App;
