import "./LogoScroller.css"

const logos = [
  "https://upload.wikimedia.org/wikipedia/en/6/6a/Fremont_Unified_School_District_Logo.png",
  "https://enroll.musd.org/AppSettings/DistrictLogo.png",
  "https://www.fresnomaderahigheredforall.org/wp-content/uploads/2021/11/fusd_logo.png",
  "https://upload.wikimedia.org/wikipedia/commons/a/a2/SJUSD_Logo_FullColor_Name.png",
  "https://upload.wikimedia.org/wikipedia/en/b/b6/San_Francisco_Unified_School_District_logo.jpg"

];

const LogoScroller = () => {
  return (
    <div className="container">
      <div className="animation-wrapper">
     
        {[...logos, ...logos].map((logo, index) => (
          <img
            key={index}
            className="logo"
            src={logo}
            alt={`Ivy League Logo ${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default LogoScroller;
