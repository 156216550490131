import React from "react";
import "./AboutUs.css"; // Optional: For styling if needed

function AboutUs() {
  return (
    <div className="home-page">
      <nav className="navbar">
        <ul className="nav-links">
          <a href="/" style={{ textDecoration: "none", color: "black" }}>
            {" "}
            <h1>
              Scro
              <span
                style={{ fontStyle: "italic", color: "orange", margin: "0px" }}
              >
                ll
              </span>
              er
            </h1>
          </a>
          <li className="login-button">Login</li>
        </ul>
      </nav>
      <div className="about-container">
        <h1>About Us</h1>
        <p>
          Hey gang! Welcome to{" "}
          <strong>
            Scro<span style={{ fontStyle: "italic", color: "orange" }}>ll</span>
            er
          </strong>
          , where we’re all about flipping the script on that mind-numbing,
          doom-scrolling spiral. 💀
        </p>
        <p>
          <strong>
            Scro<span style={{ fontStyle: "italic", color: "orange" }}>ll</span>
            er
          </strong>{" "}
          was brought to life by two self-proclaimed nerds 🕶️ who’ve already got
          some solid W's in the EdTech game. After crushing it with a few dope
          products, we decided it was time to tackle the beast that is social
          media brain rot. You know those endless, mindless scroll sessions on
          TikTok or Insta? Yeah, we’ve been there too. And we were like, “nah,
          this ain’t it.”
        </p>
        <p>
          Let’s be real — we’ve all wasted <em>wayyy</em> too much time
          spiraling through “content” (more like distractions) that doesn’t
          actually add anything to our lives. The vibes are off, right?
        </p>
        <p>
          That’s why{" "}
          <strong>
            Scro<span style={{ fontStyle: "italic", color: "orange" }}>ll</span>
            er
          </strong>{" "}
          was born: to help you level up your scrolling game. Instead of sinking
          deeper into a black hole of cat memes and TikTok challenges, we’re all
          about making your feed <strong>educational AF</strong> but still fun
          enough to keep you hooked. 🎓✨
        </p>
        <p>
          <strong>Get scrolling, but smarter.</strong>
        </p>
      </div>
      <div className="about-container">
        <h1>
          Why We’re Free <span style={{ fontSize: "10px" }}>(almost)</span>
        </h1>
        <p>
          Yo, so here’s the deal — at{" "}
          <strong>
            Scro<span style={{ fontStyle: "italic", color: "orange" }}>ll</span>
            er
          </strong>
          , we believe learning should be for <em>everyone</em>, not just those
          who can mad cash on it (aka nepo babies, rich kids, collegemaxxers).
          🎓💸 We get it, a lot of other EdTech platforms out there are all
          about that 💰💰💰, but we’re different. Our mission? Putting education{" "}
          <strong>over</strong> profit, every single time.
        </p>
        <p>
          Look, we’re not here to throw shade (okay, maybe a little 😏), but
          let’s keep it real: some of these big EdTech companies care more about
          stacking bills than actually helping people level up. We’ve seen it.
          You’ve seen it. They’re out here slapping price tags on knowledge like
          it’s some designer drip (Chegg moment. Quizlet moment. Brainly moment. CourseHero moment.).
        </p>
        <p>
          But us?. We a lil' different.{" "}
          <strong>
            Scro<span style={{ fontStyle: "italic", color: "orange" }}>ll</span>
            er
          </strong>{" "}
          was made to help you escape the doom-scroll trap and give you the
          tools to learn, grow, and succeed — all without breaking the bank. We
          high-key care more about your education than chasing paper. 🧠✨
        </p>
        <p>
          Our goal? <strong>No one gets left behind.</strong> Whether you’re
          prepping for your next test or just trying to get your brain some
          solid gains, we’ve got your back. And the best part? It’s all free.
          Because we know the struggle is real, and we’re not about to gatekeep
          knowledge.
        </p>
        <p>
          <strong>
            So go ahead, get your scroll on — smarter, not harder. 🚀
          </strong>
        </p>
        <p>
          Okay, we actually lied a little. Everything is free, but there will be
          some paid plans in the future to help us stay in business. It costs a
          lot to keep our servers running and pay bills for our API requests.
        </p>
        <p style={{ fontFamily: "cursive" }}>
          ~ sriram & rishit 
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
