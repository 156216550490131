import React from "react";

const QuestionCardLandingPage = ({ question, choices, title, color }) => {
  const cardStyle = {
    cardColor: "whitesmoke",
    textColor: "black",
    buttonColor: "whitesmoke",
  };

  return (
    <div>
      <div
        style={{
          background: `whitesmoke`,
          borderRadius: "10px",
          boxShadow: `0px 0px 20px 1px ${color}20`,
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          border: `1px solid ${color}80`,
          width:"260px",
          height:"56vh"
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: `${color}20`,
              margin: "-10px",
              padding: "10px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                color: color,
                backgroundColor: `${color}08`,
                padding: "1px 15px",
                outline: `1px solid ${color}`,
                borderRadius: "100px",
                width: "fit-content",
              }}
            >
              {title}
            </p>
          </div>

          <p
            style={{
              fontSize: question.length < 150 ? "18px" : "18px",
              marginTop: "20px",
              color: cardStyle.textColor,
              height: "150px",
              overflow: "hidden",
              marginBottom: "10px",
            }}
          >
            {question}
          </p>
        </div>
        <div>
          {choices.map((choice, index) => (
            <button
              key={index}
              style={{
                display: "block",
                width: "100%",
                border: "1px solid gainsboro",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "10px",
                textAlign: "left",
                fontSize: choice.length > 60 ? "12px" : "14px",
                cursor: "default",
                backgroundColor: cardStyle.buttonColor,
                color: cardStyle.textColor,
                cursor: "not-allowed"
                
              }}
              disabled={true}
            >
             {choice}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionCardLandingPage;
