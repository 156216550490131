import "./App.css";
import { useState, useEffect, useRef } from "react";
import LogoScroller from "./LogoScroller";
import QuestionCardLandingPage from "./QuestionCard";

function Home() {
  const handleRedirect = () => {
    window.location.href = "https://scrollerstudy.app/";
  };
  const demoQuestions = [
    {
      title: "Geography",
      question:
        "What is the capital of France? **Hint:** It's also known as the city of lights.",
      choices: ["Paris", "London", "Berlin", "Madrid"],
      answer: "Paris",
      color: "#ff5722",
    },
    {
      title: "Math",
      question: "What is the derivative of the function f(x) = 2x^2 + 3x + 1?",
      choices: ["4x + 3", "2x + 3", "4x - 3", "None of the above"],
      answer: "4x + 3",
      color: "#4caf50",
    },
    {
      title: "History",
      question: "Who was the first President of the United States?",
      choices: [
        "George Washington",
        "Thomas Jefferson",
        "Abraham Lincoln",
        "John Adams",
      ],
      answer: "George Washington",
      color: "#3f51b5",
    },
    {
      title: "Science",
      question: "What is the chemical symbol for water?",
      choices: ["O2", "H2O", "CO2", "NaCl"],
      answer: "H2O",
      color: "#9c27b0",
    },
    {
      title: "Literature",
      question: "Who wrote the novel **1984**?",
      choices: ["George Orwell", "Aldous Huxley", "Mark Twain", "J.K. Rowling"],
      answer: "George Orwell",
      color: "#e91e63",
    },
    {
      title: "Technology",
      question: "What does **HTTP** stand for?",
      choices: [
        "HyperText Transfer Protocol",
        "High-Tech Transfer Process",
        "HyperText Transmission Program",
        "None of the above",
      ],
      answer: "HyperText Transfer Protocol",
      color: "#009688",
    },
  ];

  const faqData = [
    {
      question: "What is Scroller?",
      answer:
        "Scroller is a platform designed for interactive studying, similar to TikTok. Students can create subjects, upload their own lecture notes, and contribute to an infinite feed of multiple-choice questions based on those subjects. This dynamic approach makes studying more engaging and personalized. Users can also save questions for later review to aid their learning process.",
    },
    {
      question: "Scroller vs Cookr vs Quizlet vs ChatGPT",
      answer:
        "Scroller has features and functions that other platforms do not provide.",
    },
    {
      question: "Is Scroller free to use?",
      answer:
        "YES! Scroller is completely free to use. All existing features will be free to all users. However, in the future, there will be paid plans with exclusive features, perks and benefits that will not be available for users in the free plan.",
    },
    {
      question: "How do I get started with Scroller?",
      answer:
        "To get started, visit the Scroller website and sign up with your email or Google account. After creating an account, you can start creating subjects and uploading your own study materials. You can also browse the feed of multiple-choice questions related to various topics, making your study sessions more interactive.",
    },
    {
      question: "Is Scroller available as a mobile app?",
      answer:
        "Currently, Scroller does not have a mobile app. However, the website is fully optimized for mobile use, so you can access all features and functionalities from your mobile browser. Expect a mobile version that could be installed via the app store mid 2025; we are trying out hardest to push new features for our existing users! ",
    },
    {
      question: "What types of content can I find on Scroller?",
      answer:
        "Scroller offers a variety of content through an interactive feed of multiple-choice questions based on subjects created by users. You can find content related to any study topic, from biology to physics and beyond, all generated from your uploaded materials.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "For support, please email us at scrollercontact@gmail.com. Our team is available to assist with any questions or issues you may have regarding the platform.",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="home-page">
      <nav className="navbar">
        <ul className="nav-links">
          <a href="/" style={{ textDecoration: "none", color: "black" }}>
            {" "}
            <h1>
              Scro
              <span
                style={{ fontStyle: "italic", color: "orange", margin: "0px" }}
              >
                ll
              </span>
              er
            </h1>
          </a>

          <li onClick={handleRedirect} className="login-button">
            Login
          </li>
        </ul>
      </nav>
      <div className="top-box">
        <p
          style={{
            margin: "50px 0px 0px 0px",
            background: "white",
            borderRadius: "100px",
            boxShadow: "0px 0px 10px 1px gainsboro",
            padding: "5px 20px",
          }}
        >
          Used by 1000+ Students
        </p>
        <br></br>

        <h1 style={{ fontSize: "70px", margin: "50px 0px 0px 0px" }}>
          The{" "}
          <span
            style={{ textShadow: "-2px -2px 1px #ff0050, 2px 2px 1px #00f2ea" }}
          >
            TikTok
          </span>
        </h1>
        <h1 style={{ fontSize: "70px", margin: "20px", textAlign:"center" }}>
          for{" "}
          <span style={{ fontStyle: "italic", color: "orange" }}>Engaging</span>{" "}
          Learning
        </h1>
        <p>Learn and prep for your courses in a familiar way -- Scrolling! </p>
        <div className="button-row">
          <a
            href="https://scrollerstudy.app/"
            style={{ textDecoration: "none" }}
          >
            <button className="button learn-more">Get Started</button>
          </a>
          <a
            href="https://scrollerstudy.app/"
            style={{ textDecoration: "none" }}
          >
            <button
              className="button learn-more"
              style={{
                backgroundColor: "#7289da",
                border: "1px solid #7289da",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "110%",
              }}
            >
              <svg
                height={15}
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
              </svg>
              Join Discord
            </button>
          </a>
        </div>

        <div
          style={{
            marginTop: "20px",
            boxShadow: "0px 0px 10px 1px gray",
            borderRadius: "10px",
            display: "flex",
            marginTop: "100px",
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{ borderRadius: "10px", width: "80vw" }}
          >
            <source src={require("./assets/video.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <h1
        style={{ margin: "100px 0px", fontSize: "30px", textAlign: "center" }}
      >
        Used by Students in
      </h1>
      <LogoScroller></LogoScroller>
      <h1 style={{ textAlign: "center", margin: "100px", fontSize: "50px" }}>
        What can you do with Scroller?
      </h1>
      <div className="bento-box">
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            <div
              style={{
                width: "500px",
                padding: "10px",
                borderRadius: "10px",
                height: "200px",
                marginBottom: "10px",
                boxShadow: "0px 0px 10px 1px gray",
              }}
            >
              <h2 style={{ margin: "0px", color: "orange" }}>
                AI-Powered Learning
              </h2>
              <p>
                Scroller is an AI-powered platform that brings education and
                TikTok together. Create or answer questions based on your study
                materials and subjects of choice. Get the same feel of TikTok
                with the ever familiar scrolling format.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "200px",
                  padding: "10px",
                  borderRadius: "10px",
                  height: "200px",
                  marginRight: "10px",
                  boxShadow: "0px 0px 10px 1px gray",
                }}
              >
                <h3 style={{ margin: "0px", color: "orange" }}>Streaks & XP</h3>
                <p>
                  Make learning fun with gamified elements such as streak and
                  XP!
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <span
                    style={{
                      position: "relative",
                      fontSize: "70px",
                      bottom: "20px",
                    }}
                  >
                    🔥
                  </span>
                  <span
                    style={{
                      position: "relative",
                      fontSize: "70px",
                      bottom: "20px",
                    }}
                  >
                    🏆
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "270px",
                  padding: "10px",
                  borderRadius: "10px",
                  height: "200px",
                  boxShadow: "0px 0px 10px 1px gray",
                }}
              >
                <h3 style={{ margin: "0px", color: "orange" }}>
                  Unlimited Personaliziation
                </h3>
                <p>
                  Learn about anything you want, from astronomy to zoology.
                  Generate whatever questions you like, in whichever way you
                  prefer, whenever you want.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div
              style={{
                width: "300px",
                padding: "10px",
                borderRadius: "10px",
                height: "250px",
                marginBottom: "10px",
                boxShadow: "0px 0px 10px 1px gray",
              }}
            >
              <h2 style={{ margin: "0px", color: "orange" }}>Videos</h2>
              <p>
                Scroller is one-of-a-kind. No other platform allows you to
                generate videos for any of your subjects. Using proprietary
                techniques, Scroller provides a solid visual-learning experience
                to assist you in your academic endeavors.
              </p>
            </div>
            <div
              style={{
                width: "300px",
                padding: "10px",
                borderRadius: "10px",
                height: "150px",
                boxShadow: "0px 0px 10px 1px gray",
              }}
            >
              <h3 style={{ margin: "0px", color: "orange" }}>No Cost.</h3>
              <p>
                Scroller has no pay wall. We want y'all to lock in and money
                shouldn't be a deciding factor when accessing education.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile-placeholder">
          {/* Top Mobile Header (simulating camera notch) */}
          <div className="mobile-header"></div>
          <div style={{ overflow: "scroll", height: "68vh" }}>
            {demoQuestions.map((item, index) => (
              <div style={{ marginBottom: "60px" }}>
                <QuestionCardLandingPage
                  key={index}
                  title={item.title}
                  question={item.question}
                  choices={item.choices}
                  color={item.color}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              width: "310px",
              backgroundColor: "white",
              height: "40px",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ margin: "0px 20px", fontSize: "10px" }}>
              Streak 🔥: 10
            </p>
            <p style={{ margin: "0px 20px", fontSize: "10px" }}>XP 🏆: 4000</p>
          </div>
        </div>
      </div>

      <div className="faq-page">
        <h1 className="faq-title">Frequently Asked Questions</h1>
        {faqData.map((item, index) => (
          <div key={index} className="faq-section">
            <div
              className={`question ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleAnswer(index)}
            >
              {item.question}
            </div>
            <div
              className="answer"
              style={{ display: activeIndex === index ? "block" : "none" }}
            >
              {item.answer}
            </div>
            {index < faqData.length - 1 && <div className="divider"></div>}
            <div style={{ height: "20px" }}></div>
          </div>
        ))}
      </div>
      <div className="get-started-section">
        <h2 className="get-started-title">Ready To Start Scrolling?</h2>
        <p className="get-started-subtitle">
          Join us today and transform the way you study with interactive content
          and endless learning opportunities.
        </p>
        <a href="https://scrollerstudy.app/">
          <button className="get-started-button">Let's Go!</button>
        </a>
      </div>
      <div className="footer">
        <div className="footer-content">
          <div className="footer-column">
            <h1>
              Scro
              <span
                style={{ fontStyle: "italic", color: "orange", margin: "0px" }}
              >
                ll
              </span>
              er
            </h1>{" "}
            <p style={{ color: "black" }}>TikTok for Learning</p>
            <div style={{ height: "20px" }}></div>
          </div>
          <div className="footer-column">
            <h3>Company</h3>
            <p>
              <a href="/aboutus">About Us</a>
            </p>
            <p>
              <a href="https://scrollerstudy.app/careers">Careers</a>
            </p>
            <p>
              <a href="/">Support Us!</a>
            </p>
          </div>
        </div>
        <div style={{ height: "30px" }}></div>
        <div className="footer-bottom">
          <p>© 2024 · Scroller · Made with ❤️ & ☕</p>
        </div>
      </div>

      <div className="content">{/* Other sections of your homepage */}</div>
    </div>
  );
}

export default Home;
